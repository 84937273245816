// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Border
$zorg-border	 : #7f9b90;
$onderwijs-border: #36417C;

// Scan body text
$zorg-text: #4B6B5D;
$onderwijs-text: #36417C;

// Scan text in colored background
$zorg-bg-text: #4B6B5D;
$onderwijs-bg-text: #36417C;

//checkmark score, th background, button-background on hover
$zorg-dark: #668377;
$onderwijs-dark: #36417C;

//border, button-background
$zorg-medium: #7f9b90;
$onderwijs-medium: #47558F;

// td background
$zorg-light: #C0CCC9;
$onderwijs-light: #B9C2DD;

